import { mergeTranslations, useTranslation } from "@crehana/i18n";
import { MAIN_MENU_TRANSLATION_KEY } from "../constants";
import locales from "../i18n";
import useMainMenuContext from "./useMainMenuContext";

/**
 * Prevent merging and adding translations on every use of the hook
 * injectOnce checks internally but it is still costly and there's no way to
 * avoid checking on every rerender currently.
 */
var RESOURCES_ADDED = false;
var useMainMenuTranslation = function useMainMenuTranslation() {
  var ctx = useTranslation(MAIN_MENU_TRANSLATION_KEY);
  var _useMainMenuContext = useMainMenuContext(),
    customLocales = _useMainMenuContext.customLocales;
  if (!RESOURCES_ADDED) {
    var merged = mergeTranslations(locales, customLocales || {});
    ctx.addResourceBundle(merged);
    RESOURCES_ADDED = true;
  }
  return ctx;
};
export default useMainMenuTranslation;